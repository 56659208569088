<template>
  <title>Team44</title>
  <navigation-header></navigation-header>
  <router-view></router-view>
</template>

<script>
import NavigationHeader from "./components/ui/NavigationHeader.vue";

export default {
  name: "App",
  components: {
    NavigationHeader,
  },
};
</script>

<style>
body {
  width: 90%;
  margin: 0 auto;
  padding: 0;
  font-family: arial;
}
</style>
