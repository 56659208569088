<template>
  <h3>Welkom op de site van Team 44.</h3>
  <p>
    Een samenwerkingsverband van enkele bedrijfsartsen. Een nieuwe organisatie
    maar gezamenlijk gemakkelijk meer dan 100 jaar ervaring in de
    bedrijfsgeneeskunde.
  </p>
  <p>Gedreven vanuit de overtuiging dat het beter kan.</p>
  <p>
    Waar klassieke marktpartijen de grijsgedraaide plaat steeds weer draaien en
    eigenlijk oude wijn in nieuwe zakken verkopen doen wij het anders.
  </p>
  <h3>Hoe we dat doen?</h3>
  <p>
    We richten voor onze opdrachtgevers dedicated teams in. Waarbij we de wensen
    van onze klant met onze expertise bundelen. Deze teams bestaan uit
    verschillende disciplines en werken steeds op locatie. Zo zijn we herkenbaar
    en aanspreekbaar. En worden we een integraal onderdeel van bedrijf of
    instelling.
  </p>
  <p>
    Daar waar het om verzuim gaat kunnen we gezamenlijk bereiken dat er steeds
    snelle en mensgerichte oplossingen voorhanden zijn. Samenwerking met uw
    huisverzekeraar? Vanzelfsprekend. Snelle toegang tot kwalitatief
    hoogwaardige zorg? Natuurlijk.
  </p>
  <p>
    Voorkomen is natuurlijk beter. Natuurlijk bieden we het gebruikelijke
    arsenaal van keuringen en screeningen maar we zijn er vooral van overtuigd
    dat gezondheid begint bij een vitale leefstijl en werk dat je met plezier
    doet, waar waardering en ideale omstandigheden vanzelfsprekend zijn. Werk
    waar je trots op kan zijn!
  </p>
</template>

<script>
export default {
    
}
</script>