<template>
  <div class="header">
    <h1 class="logo">team<span class="logo-44">44</span></h1>
    <nav>
      <ul>
        <li><router-link class="routerLink" to="/">Team44</router-link></li>
        <li>
          <router-link class="routerLink" to="/dedicated-teams"
            >Dedicated teams</router-link
          >
        </li>
        <li>
          <router-link class="routerLink" to="/gezond-aan-het-werk"
            >Gezond aan het werk</router-link
          >
        </li>
      </ul>
    </nav>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Bodoni+Moda&display=swap");
.header {
  display: flex;
  max-width: 100%;
}

.logo {
  color: #3E552A;
  font-family: "Bodoni Moda", serif;
}

.logo-44 {
  color: #538135;
}

nav {
  width: 50%;
  padding: 15px;
  margin: 0 auto;
}

ul {
  display: flex;
  justify-content: space-between;
}

li {
  list-style-type: none;
  color: black;
  text-align: center;
  font-size: 20px;
}

.routerLink {
  text-decoration: none;
  color: black;
}

.router-link-active {
  color: green;
  border-bottom: 3px solid green;
}
</style>
