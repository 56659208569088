import { createApp } from "vue";
import { createRouter, createWebHistory } from "vue-router";
import App from "./App.vue";
import HomePage from "./components/HomePage.vue";
import DedicatedTeams from "./components/DedicatedTeams.vue";
import GezondWerk from "./components/GezondWerk.vue";

const app = createApp(App);

const router = createRouter({
  history: createWebHistory(),
  routes: [
    { path: "/", component: HomePage },
    { path: "/dedicated-teams", component: DedicatedTeams },
    { path: "/gezond-aan-het-werk", component: GezondWerk },
  ],
});

app.use(router);
app.mount("#app");
