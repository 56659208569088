<template>
<h2>Gezond aan het werk</h2>
  <p>
    Werk waar je trots op bent. Waar je met plezier je energie insteekt. En
    merkt dat je daar ook weer energie van krijgt. Zo vanzelfsprekend en
    tegelijk ook iets waar veel mensen mee worstelen.
  </p>
  <p>
    We maken steeds een analyse waar de schoen knelt en welke oplossingen
    daarbij passen. Waarbij we de medewerker altijd als vertrekpunt nemen. Een
    goede balans is een voorwaarde voor topprestaties.
  </p>
  <p>
    En natuurlijk moet de organisatie datzelfde principe ademen. Investeren in
    medewerkers en oprechte belangstelling hebben voor hun verlangens is een
    tweede randvoorwaarde om tot gezamenlijk succes te komen
  </p>
</template>
