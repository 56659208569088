<template>
  <h2>Dedicated teams</h2>
  <p>
    Voor onze opdrachtgevers stellen we de teams op maat samen. De samenstelling
    van het team kan variëren, meer een veelgebruikte samenstelling is bv.
    bedrijfsarts, psycholoog, bewegingswetenschapper en de werknemerscoach.
    Natuurlijk met een geoliede administratieve ondersteuning.
  </p>
  <p>
    We leveren aan onze opdrachtgevers altijd een overzicht van alle spelers en
    hoe ze bereikbaar zijn. Bijvoorbeeld op het intranet. Om zo die goede
    inbedding in de organisatie te bereiken.
  </p>
  <p>
    Waarbij we natuurlijk wel zorgen voor optimale privacy en
    gegevensbescherming.
  </p>
</template>
